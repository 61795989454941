import { useNavigate } from 'react-router-dom';
import './home.css'

const Home = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='nav'>
                <div className='nav-field'>C++ cheat sheet
                    <ul className='nav-field-dropdown'>
                        <li onClick={() => navigate('/c++/integer')}>Integer</li>
                        <li>String</li>
                        <li>Array</li>
                        <li onClick={() => navigate('/c++/vector')}>vector</li>
                        <li>Map</li>
                        <li>Set</li>
                        <li>Stack</li>
                        <li>Queue</li>
                        <li>Deque</li>
                    </ul>
                </div>
                <div className='nav-field'>Python cheat sheet
                    <ul className='nav-field-dropdown'>
                        <li>Integer</li>
                        <li>String</li>
                        <li>List</li>
                        <li>Tuple</li>
                        <li>Dictionary</li>
                        <li>Set</li>
                        <li>Stack</li>
                        <li>Queue</li>
                        <li>Deque</li>
                    </ul>
                </div>
                <div className='nav-field'>Data Science
                    <ul className='nav-field-dropdown'>
                        <li>Statistics</li>
                        <li>Numpy</li>
                        <li>Pandas</li>
                        <li>Matplotlib</li>
                        <li>Scipy</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Home;