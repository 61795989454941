import { useNavigate } from 'react-router-dom';
import './home.css'

const IndexHome = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className='link' onClick={() => navigate('/cheatsheet')}>Cheat Sheet</div>
            <div className='link' onClick={() => navigate('/health')}>health</div>
        </>
    )
}

export default IndexHome;