import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CheatSheetHome from './cheat-sheet/home'
import CppVector from './cheat-sheet/cpp/vector'
import IndexHome from './home'
import HealthHome from './health/healthHome'
import TsItems from './ts-items/ts-items'
import Pfngt from './pfngt/pfngt';


import './App.css';

function App() {
  return (
    <Router>
      <Routes>

        <Route path="/" element={
          <>
            <h1>in main branch</h1>
            <IndexHome />
          </>
        } />
        <Route exact path="/cheatsheet" element={<CheatSheetHome />} />
        <Route exact path="/c++/vector" element={<CppVector />} />
        <Route exact path="/health" element={<HealthHome />} />
        <Route exact path="/tsitems" element={<TsItems />} />
        <Route exact path="/pfngt" element={<Pfngt />} />
      </Routes>
    </Router>
  );
}

export default App;
