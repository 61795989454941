import { useNavigate } from 'react-router-dom';

import './healthHome.css'

const HealthHome = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className='heading-1'>"Health is the ultimate negative idea, You don’t think about it until you lose it."</div>
            <div onClick={() => navigate(-1)}> go back</div>

            <div>
                <div className='external-link' onClick={() => { window.open('https://drwilliamli.com/the-body-can-heal-itself/') }}>An introduction to the healing power of body by Dr. William Li</div>
                <div className='external-link' onClick={() => { window.open('https://drwilliamli.com/your-body-the-new-homeland-defense/') }}>Your Body: The New Homeland Defense by Dr. William Li</div>
                <div className='external-link' onClick={() => { window.open('https://www.bobandbrad.com/') }}>Internet's most famous physiotherapist</div>
            </div>
        </>
    )
}

export default HealthHome;