
import './ts-items.css'

const TsItems = () => {

    return (
        <>
            <div >Wlelyoucmaetishfarmaensyoukonwsmeoihtngbutformhreejnorueywlilbemchuhmroediifculftkonwhreegeosnhoitng
                <br />
                #howaoubtsayjmeasbnodimgvid1234001007
            </div>

        </>
    )
}

export default TsItems;