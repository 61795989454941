
import './pfngt.css'

const Pfngt = () => {

    return (
        <>
            <div >
                can tight shoulders affect breathing
                <ol>
                    <li> <a href='https://www.healthline.com/health/breathe-deeper-improve-health-and-posture#takeaway'> https://www.healthline.com/health/breathe-deeper-improve-health-and-posture#takeaway</a></li>
                    <li> <a href='https://www.medicalnewstoday.com/articles/chest-pain-when-bending-forward'> https://www.medicalnewstoday.com/articles/chest-pain-when-bending-forward</a></li>
                </ol>
                <br />
                <a href='https://www.youtube.com/watch?v=sBJkGoScFjM'> Got pain while working at computer</a>
                <br />
                <a href='https://www.youtube.com/watch?v=cHA_lQz8SIw'> 10 Habits Causing Your Pain</a>
                <br />
                <a href='https://www.youtube.com/watch?v=TuoDD7lm9AE'> Are Your Chest Muscles Tight (Pec Major or Minor) How To Tell</a>
                <br />
                <a href='https://www.youtube.com/watch?v=2oXoiQfija4'>3 Breathing Exercises That Can Change Your Life!</a>
                <br />
                <a href='https://www.youtube.com/watch?v=_QTJOAI0UoU'>Change Your Breath, Change Your Life | Lucas Rockwood </a>
                <br />
                <a href='https://www.youtube.com/watch?v=hFcQpNr_KA4'>Breath -- five minutes can change your life | Stacey Schuerman</a>


            </div>

        </>
    )
}

export default Pfngt;