import { useNavigate } from 'react-router-dom';

const CppVector = () => {
    const navigate = useNavigate();

    return (
        <>
            <div>in c++ vector</div>
            <div onClick={() => navigate(-1)}> go back</div>
        </>
    )
}

export default CppVector;